import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/cost-capitalization-meta.png'
import ChartIcon from '../../assets/images/home/icons/chart.svg'
import CogIcon from '../../assets/images/home/icons/cog.svg'
import CompassIcon from '../../assets/images/home/icons/compass.svg'
import tag from '../../assets/images/tags/cost-capitalization-tag.svg'
import Layout from '../../components/Layout'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import FAQ from '../../components/CostCapFAQ'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'
import MainDemoRequestCTA from '../../components/MainDemoRequestCTA'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/swarmia-metrics.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(
      relativePath: { eq: "images/product/software-capitalization/lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/get-data-cost-cap-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/no-overhead-cost-cap-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/report-cost-cap-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
  }
`

const CostCapPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Flexible software cost capitalization reporting"
      variant="dark"
      isNew
      description="Get the exact data you need to create customizable, audit-ready software capitalization reports."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Flexible software cost capitalization reporting</>}
        content="Get the exact data you need to create customizable, audit-ready software capitalization reports."
        backgroundImage={getImage(data.backgroundImage)!}
        customCta={<MainDemoRequestCTA skipToCalendar="otto" />}
      />
      <LeadBlock
        heading="The simple way to report on the cost of software development"
        content="Use the Investment Balance view in Swarmia to set up capitalizable investment categories and blend with salary data to get a full picture of the cost of building software."
        link={{
          title: 'Read more about investment balance',
          href: '/product/investment-balance/',
        }}
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <ColumnsBlock
        paddingTop={responsiveScale(32)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Configure investment categories"
            text="Capture capitalizable developer effort by setting up auto-categorization rules."
            icon={CogIcon}
          />,
          <Blurb
            key="2"
            title="Blend with team and salary data"
            text="Easily adjust variables such as team structure and salary data to improve accuracy."
            icon={CompassIcon}
          />,
          <Blurb
            key="3"
            title="Gain visibility into CapEx and costs"
            text="Get PDF and spreadsheet reports with minimal effort and at your chosen cadence."
            icon={ChartIcon}
          />,
        ]}
      />
      <QuoteBlock person="pau" />
      <LeadBlock
        heading="Why modern software organizations choose Swarmia"
        content="Swarmia makes cost estimation and capitalization reporting flexible for finance teams, painless for engineers, and ready for auditors."
      />
      <MosaicBlock
        title={<>Customizable reports for&nbsp;finance teams</>}
        content="With Swarmia, your finance and accounting teams can look at capitalizable work as monthly aggregates or filtered by individual developer or line item. The format of the report makes it easy to adjust the key variables that affect cost estimates and capitalization."
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title={<>No overhead for engineering&nbsp;teams</>}
        content="Swarmia’s auto-categorization rules catch over 90% of the work engineers are doing. As for the remaining 10%, engineers can link their work items to investment categories in Slack to completely eliminate the need for manual time tracking."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <MosaicBlock
        title="Better data quality"
        content="Swarmia models the effort of each software engineer based on their GitHub contributions and issue tracker activity. Since engineering teams often use Swarmia in their daily work, our data quality is generally higher than that of competing solutions."
        image={getImage(data.mosaicLeft2)!}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title="Ready for a fast and flexible way to create cost capitalization reports?"
          customCta={<MainDemoRequestCTA skipToCalendar="otto" />}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/balancing-engineering-investments/',
            '/blog/measuring-software-development-productivity/',
            '/blog/when-to-start-a-platform-team/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default CostCapPage
